// JS-Funktionen in einzelnen Datein unter "components":
import { consoleMessage } from './components/console-message';
import { externalLinks } from "./components/external-links";
import { generalFunctions } from "./components/general-functions";
import { htmxFunctions } from './components/htmx';
// import { popoverLists } from './components/bs-popover-lists';
// import { toastsList } from './components/bs-toasts-list';

generalFunctions();

document.addEventListener('DOMContentLoaded', () => {
  /* eslint-disable no-new, no-restricted-syntax */
  consoleMessage();
  externalLinks();
});

// re-init after HTMX swap
document.addEventListener('htmx:afterSwap', (evt) => {
  htmxFunctions(evt);
});
